.pdf-book {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
  }
  
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid #ddd;
  }
  
  .page img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  